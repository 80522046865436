import { AccRoles, AppRoles, Permissions } from './permissions.enum';

export const PERMISSIONS_CONFIG = {
  // Field tasks
  [Permissions.FieldTasksViewNavButton]: [
    AppRoles.Internal, AppRoles.GeneralUserFt, AppRoles.PowerUserFt, AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.OffCycleOrderAdd]: [
    AppRoles.Internal, AppRoles.GeneralUserFt, AppRoles.PowerUserFt, AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.FieldTasksViewServiceTasks]: [
    AppRoles.Internal, AppRoles.GeneralUserFt, AppRoles.PowerUserFt, AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.FieldTasksViewContiOrders]: [
    AppRoles.Internal, AppRoles.GeneralUserFt, AppRoles.PowerUserFt, AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.FieldTasksViewBatchOrders]: [
    AppRoles.Internal, AppRoles.GeneralUserFt, AppRoles.PowerUserFt, AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.FieldTasksViewStimOrders]: [
    AppRoles.Internal, AppRoles.GeneralUserFt, AppRoles.PowerUserFt, AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.FieldTasksViewDeliveryList]: [
    AppRoles.Internal, AppRoles.GeneralUserFt, AppRoles.PowerUserFt, AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.FieldTasksSelectOrders]: [
    AppRoles.GeneralUserFt, AppRoles.PowerUserFt, AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.FieldTasksLoadProduct]: [
    AppRoles.GeneralUserFt, AppRoles.PowerUserFt, AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.FieldTasksUnloadProduct]: [
    AppRoles.GeneralUserFt, AppRoles.PowerUserFt, AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.FieldTasksAllAccessOrders]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.FieldTasksAllAccessServiceTasks]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
  ],

  // Landing page
  [Permissions.LandingCreateAccount]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.LandingAddSoldTo]: [
    AppRoles.Admin, AppRoles.SuperAdmin, AppRoles.Internal,
  ],
  [Permissions.LandingDeactivateAccount]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.LandingReactivateAccount]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
  ],

  // Account page
  [Permissions.AccountView]: [
    AppRoles.Internal, AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.AccountViewDashboard]: [
    AppRoles.Internal, AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.AccountViewManagement]: [
    AppRoles.Internal, AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.AccountViewPerformance]: [
    AppRoles.External, AppRoles.Internal, AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.ExternalUser, AccRoles.ExternalUserBI,
  ],
  [Permissions.AccountViewSupport]: [
    AppRoles.Internal, AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.AccountViewSettings]: [
    AppRoles.Internal, AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.GeneralUser, AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountViewNotifications]: [
    AppRoles.Internal, AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.GeneralUser, AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountViewCalendar]: [
    AppRoles.Internal, AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.GeneralUser, AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountCreateNote]: [
    AppRoles.Internal, AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.GeneralUser, AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountViewKpiControls]: [
    AppRoles.Internal, AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.GeneralUser, AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountViewCustomizationReports]: [
    AppRoles.SuperAdmin, AppRoles.Admin, AppRoles.Internal, AppRoles.External,
    AccRoles.AccountCustomReportView,
  ],
  [Permissions.AccountEditCustomizationReports]: [
    AccRoles.PowerUserBI, AccRoles.ExternalUserBI,
  ],
  [Permissions.AccountEditKpiControls]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountViewAllAssetsData]: [
    AppRoles.Internal, AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.GeneralUser, AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountWriteAllAssetsData]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountViewContacts]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountCreateContact]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountEditContact]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountRequestAccessForContact]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountApproveAccessForContact]: [
    AppRoles.SuperAdmin,
  ],
  [Permissions.AccountSyncCrew]: [
    AppRoles.SuperAdmin,
  ],
  [Permissions.AccountRemoveContact]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountSaveChart]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountRemoveBatchTreatment]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountRemoveContinuousTreatment]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountSuspendContinuousTreatment]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.AccountRemoveStimTreatments]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.ApproveChangesKpi]: [
    AppRoles.Admin, AppRoles.SuperAdmin,
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],

  // Service task
  [Permissions.ServiceTaskAccountViewInventories]: [
    AccRoles.GeneralUser, AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.ServiceTaskAccountViewSamples]: [
    AccRoles.GeneralUser, AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.ServiceTaskCaptureInventory]: [
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.ServiceTaskCaptureSample]: [
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],

  // Order account
  [Permissions.OrderAccountViewOrderStatus]: [
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.OrderAccountViewOrderTargetVolume]: [
    AccRoles.GeneralUser, AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.OrderAccountEditPendingOrder]: [
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.OrderAccountEditCompletedOrder]: [
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.OrderAccountCancelOrder]: [
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.OrderAccountCreateNonRoutineOrder]: [
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.OrderAccountViewOrderComments]: [
    AccRoles.GeneralUser, AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.OrderAccountCreateOrderComment]: [
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.OrderAccountBypassDeliveryCapture]: [
    AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.OrderAccountDeliveryCapture]: [
    AccRoles.GeneralUser, AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  [Permissions.OrderAccountSelectOrder]: [
    AccRoles.GeneralUser, AccRoles.PowerUser, AccRoles.SuperVisor,
    AccRoles.PowerUserBI,
  ],
  // documents
  [Permissions.DocumentsShowAccess]: [
    AppRoles.Internal, AppRoles.GeneralUserFt, AppRoles.PowerUserFt, AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.DocumentsUpdateItems]: [
    AppRoles.PowerUserFt, AppRoles.Admin, AppRoles.SuperAdmin,
  ],
  [Permissions.ContactsUpdateItems]: [
    AccRoles.PowerUser, AppRoles.Admin, AppRoles.SuperAdmin, AccRoles.SuperVisor,
  ],
  [Permissions.ContactsGrantAccessItems]: [
    AppRoles.Admin, AppRoles.SuperAdmin, AccRoles.SuperVisor,
  ],
  [Permissions.NotificationsView]: [
    AppRoles.Internal, AppRoles.Admin, AppRoles.SuperAdmin,
    AppRoles.GeneralUserFt, AppRoles.PowerUserFt, AccRoles.SuperVisor,
  ],
  [Permissions.SamplesEdit]: [
    AppRoles.Admin, AppRoles.SuperAdmin, AccRoles.PowerUser, AccRoles.PowerUserBI,
  ],
};
